import React from 'react';
import { Link, graphql } from 'gatsby';
import _ from 'lodash';

import BlogLayout from '../components/BlogLayout';
import SeoHelmet from '../components/SeoHelmet';
import { getLearnPath } from '../utils/routes';
import BlogListingNav from '../components/BlogListingNav';
import styled from 'styled-components';
import formatReadingTime from '../utils/formatReadingTime';
import { supportedLanguages } from '../i18n';
import BlogLanguageSelector from '../components/BlogLanguageSelector';
import BlogSimulatorPromo from '../components/BlogSimulatorPromo';

const ListingTitle = styled.h1`
  margin-top: 12px;
  margin-bottom: 6px;
`;
const PostTitle = styled.h3`
  margin-bottom: 4px;
`;

const PostTitleLink = styled(Link)`
  box-shadow: none;
`;

const PostDate = styled.small`
  color: #a2a2a2;
  margin-bottom: 6px;
  display: block;
`;

const BlogPaginatedPostsTemplate = ({
  data,
  location,
  pageContext: { previousPageLink, nextPageLink, lang, prefix, title },
}) => {
  const posts = _.get(data, 'allMdx.nodes');

  return (
    <BlogLayout location={location}>
      <SeoHelmet location={location} lang={lang} />
      {title && <ListingTitle>{title}</ListingTitle>}
      <BlogLanguageSelector
        slug={prefix}
        languages={Object.keys(supportedLanguages)}
      />
      <BlogSimulatorPromo />

      {posts.map(post => {
        return (
          <article key={_.get(post, 'fields.slug')}>
            <header>
              <PostTitle>
                <PostTitleLink
                  to={getLearnPath(
                    _.get(post, 'fields.slug'),
                    _.get(post, 'fields.lang'),
                  )}
                  state={{
                    prevLocationPathname: _.get(location, 'pathname'),
                  }}
                >
                  {post.frontmatter.title}
                </PostTitleLink>
              </PostTitle>
              <PostDate>
                {post.frontmatter.date} •{' '}
                {formatReadingTime(_.get(post, 'timeToRead'))}
              </PostDate>
            </header>
            <section>
              <p>
                {_.get(post, 'frontmatter.description') ||
                  _.get(post, 'excerpt')}
              </p>
            </section>
          </article>
        );
      })}

      <BlogListingNav
        previousPageLink={previousPageLink}
        nextPageLink={nextPageLink}
      />
    </BlogLayout>
  );
};

export default BlogPaginatedPostsTemplate;

export const pageQuery = graphql`
  query BlogPaginatedPostsTemplateQuery(
    $skip: Int!
    $limit: Int!
    $lang: String!
  ) {
    allMdx(
      filter: {
        fields: { lang: { eq: $lang } }
        frontmatter: { type: { eq: "post" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        timeToRead
        excerpt(pruneLength: 160)
        fields {
          slug
          lang
        }
        frontmatter {
          date(formatString: "DD MMMM, YYYY")
          description
          title
        }
      }
    }
  }
`;
