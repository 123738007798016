import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Nav = styled.nav`
  margin: 12px 0;
`;

const NavList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  verticalalign: center;
  margin: 0;
`;

const NavListElement = styled.li`
  margin: 0;
  padding: 0;
`;

const BlogListingNav = ({ previousPageLink, nextPageLink }) => {
  return (
    <Nav>
      <NavList>
        <NavListElement>
          {previousPageLink != null && (
            <Link to={previousPageLink} rel="prev">
              ← Previous Page
            </Link>
          )}
        </NavListElement>
        <NavListElement>
          {nextPageLink != null && (
            <Link to={nextPageLink} rel="next">
              Next Page →
            </Link>
          )}
        </NavListElement>
      </NavList>
    </Nav>
  );
};

export default BlogListingNav;
