import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { getLearnPagePath, getLearnPath } from '../utils/routes';
import { supportedLanguages } from '../i18n';

const Languages = styled.div`
  font-size: 0.9em;
  border-radius: 0.75em;
  padding: 0.75em 0;
  word-break: keep-all;
`;

const LangLink = styled(Link)`
  text-decoration: none;
  box-shadow: none;
`;

const BlogLanguageSelector = ({ index, slug, languages = [] }) => {
  if (languages.length === 0) {
    return null;
  }

  return (
    <Languages>
      Translated into:{' '}
      {languages.map((lang, i) => (
        <React.Fragment key={i}>
          <LangLink
            to={
              Boolean(index)
                ? getLearnPagePath(index, slug, lang)
                : getLearnPath(slug, lang)
            }
          >
            {supportedLanguages[lang]}
          </LangLink>
          {i < languages.length - 1 && ' • '}
        </React.Fragment>
      ))}
    </Languages>
  );
};

export default BlogLanguageSelector;
