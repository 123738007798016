import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
  border-radius: 16px;
  padding: 24px 40px;
  background: #dbdeff;
  margin: 24px 0;

  @media (max-width: 1365px) {
    padding: 24px;
  }
`;

const Link = styled.a`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #7774ff;
  text-decoration: none;
  transition: color 200ms;

  :visited {
    color: #7774ff;
  }

  :hover {
    text-decoration: none;
    color: #5451C9;
  }

  @media (max-width: 1365px) {
    font-size: 15px;
    line-height: 24px;
  }
`;

const BlogSimulatorPromo = () => {
  return (
    <Container>
      <p>Научим проектировать и&nbsp;масштабировать веб‑сервисы</p>
      <p>
        Это необычный курс. Nerdia — это&nbsp;симулятор работы
        в&nbsp;IT-компании. <Link href="/">Узнать больше</Link>
      </p>
    </Container>
  );
};

export default BlogSimulatorPromo;
