import _ from 'lodash';

const formatReadingTime = minutes => {
    const numCups = Math.ceil(minutes / 10);
    const cups = _.range(numCups)
        .map(_ => '♨')
        .join('');
    return `${cups} ${minutes} min read`;
};

export default formatReadingTime;
